.html,
body {
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.backgroundPage {
  height: 100vh;
  width: 100vw;
  color: white;
  transition: background-size 0.5s ease-in-out;
  background-image: url(./images/BackgroundOverlay.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  background-color: #0f1c2b;
}

.backgroundRepeat {
  background-repeat: repeat-y;
  height: auto;
  min-height: 100vh;
}

.header {
  height: 95px;
}

.fslogo {
  float: right;
  margin: 8px 10px;
  max-height: 85px;
  vertical-align: middle;
  max-width: 225px;
}

.searchContent {
  margin-right: 1rem;
  margin-left: 1rem;
}

.searchBar {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 135px;
}

.welcomeText {
  text-align: center;
  font-size: 60px;
  font-weight: 300;
  color: white;
}

.input-container {
  width: 90%;
  margin-right: 10%;
  margin-left: 10%;
  height: 65px;
  border-radius: 5px;
  border: 2px;
  margin-bottom: 25px;
}

.searchInput {
  width: 100%;
  display: flex;
  height: 45px;
  font-size: 20px;
  font-weight: 300;
  margin-right: 10px;
  outline: none;
}

.list.hiddenList {
  display: none;
}

ul.list {
  background: white;
  position: absolute;
  list-style: none;
  width: 70%;
  padding: 1em;
  color: #0f1c2b;
  text-decoration: none;
  max-height: 40vh;
  overflow-y: auto;
  margin: 0px;
}

ul.list > li {
  padding: 1em;
  text-decoration: none;
  list-style: none;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;
  font-style: italic;
}

.faqItem {
  padding: 2em 0;
  border-top: 1px solid #dddddd;
}

.links {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.supportBtn {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-content: space-around;
  justify-content: space-between;
  align-items: center;
  border-right: white 1px solid;
  cursor: pointer;
  min-height: 185px;
}

.faqBtn {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-content: space-around;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  min-height: 185px;
}

.contactBtn {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-content: space-around;
  justify-content: space-between;
  align-items: center;
  border-left: white 1px solid;
  cursor: pointer;
  min-height: 185px;
}

.linkTitle {
  font-size: 36px;
  font-weight: 300;
  margin: 24px 0px;
  padding: 5px;
  border-bottom: white 1px solid;
}

.extraText {
  font-size: 18px;
  text-align: center;
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 25px;
}

.largeTitle,
h1 {
  color: #0f1c2b;
  margin-bottom: 35px;
  padding-bottom: 15px;
  border-bottom: #0f1c2b 1px solid;
}

.FAQtitle {
  text-decoration: bold;
  color: #0f1c2b;
  margin-bottom: 10px;
  font-size: 18px;
}

.FAQanswers {
  color: #0f1c2b;
  margin-bottom: 10px;
  margin-left: 28px;
}

.footer {
  -moz-transition: bottom 0.3s ease-in-out;
  -o-transition: bottom 0.3s ease-in-out;
  -webkit-transition: bottom 0.3s;
  align-items: center;
  background-color: #0f1c2b;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  gap: 12px;
  height: 70px;
  justify-content: flex-start;
  padding: 0 16px 0 8px;
  position: fixed;
  transition: bottom 0.5s ease-in-out;
  width: 100vw;
}

.footer .active {
  bottom: 0;
  background-color: #0f1c2b !important;
}

.logoFooter {
  max-height: 50px;
}

.footerTxt {
  cursor: pointer;
  color: white;
}

.scrollBtn {
  border: 2px solid white;
  border-radius: 50%;
  margin-left: auto;
  cursor: pointer;
  height: 30px;
  width: 30px;
}

.wrapperFAQ {
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 65px;
  margin-bottom: 100px;
}

.formInput,
.formSelect,
.formTextArea {
  border: 1px solid #97999b;
  border-radius: 5px;
  box-sizing: border-box;
  color: #041c2c;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  outline: 0;
  padding: 0.5rem;
  position: relative;
  width: 100%;
}

.formInput:focus,
.formSelect:focus,
.formTextArea:focus {
  border: 1px solid #007398;
}

.formTextArea {
  font-family: "Inter", sans-serif;
  resize: vertical;
}

form.validated .formInput:invalid,
form.validated .formSelect:invalid,
form.validated .formTextArea:invalid,
form.validated .formCheckbox:invalid {
  border: 2px solid red;
}

form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.formInput,
.formSelect {
  box-sizing: border-box;
  height: 40px;
}

fieldset {
  border: 1px solid #ffffff;
  border-radius: 5px;
  padding: 1rem;
}

legend {
  color: #ffffff;
}

.gridWrapper {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
}

.consentWrapper {
  margin: 0.75rem 0 0 0;
}

.wrapperForm {
  margin: 0 1rem 0 1rem;
}

.captchaMargin {
  margin-bottom: 50px;
}

@media only screen and (min-device-width: 768px) {
  .wrapperForm {
    margin: 0 10% 0 10%;
  }

  .searchContent {
    margin-right: 10%;
    margin-left: 10%;
  }

  .wrapperFAQ {
    margin-left: 10%;
    margin-right: 10%;
  }

  .gridWrapper {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .row1 {
    grid-row: 1;
  }

  .row2 {
    grid-row: 2;
  }

  .row3 {
    grid-row: 3;
  }

  .row4 {
    grid-row: 4;
  }

  .span2 {
    grid-column: 1 / span 2;
  }
}

.submitButton {
  display: block;
  float: right;
  background-color: #007398;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  font-size: 16px;
  height: 40px;
  margin-top: -3.5rem;
  outline: 0;
  padding: 0.5rem 2rem;
  cursor: pointer;
}
.submitButton:hover,
.submitButton:focus {
  background-color: #1996c6;
}
.submitButton:active {
  background-color: #0d526c;
}
.submitButton:disabled {
  background-color: #98999b;
}

.returnBtn {
  background-color: #ffffff00;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  border: 1px #ffffff solid;
  font-size: 16px;
  height: 40px;
  margin-bottom: 1rem;
  outline: 0;
  padding: 0.5rem 2rem;
  cursor: pointer;
}

.returnBtn:hover,
.returnBtn:focus {
  background-color: #007398;
}
.returnBtn:active {
  background-color: #0d526c;
}

.consentMessage {
  border: 1px solid #041c2c;
  border-radius: 5px;
  margin-bottom: 1rem;
  padding: 0.5rem;
}

.requiredWrapper {
  position: relative;
  width: 100%;
}

.requiredMark {
  color: red;
  font-size: 0.75rem;
  left: 0.25rem;
  position: absolute;
  top: -0.75rem;
}

/* for phone sized screens */
@media only screen and (max-width: 480px) {
  .fslogo {
    max-height: 65px;
    max-width: 205px;
  }

  .searchBar {
    margin-top: 95px;
  }

  .welcomeText {
    font-size: 34px;
  }

  .searchInput {
    max-height: 35px;
    font-size: 18px;
  }

  ul.list > li {
    padding: 5px;
    font-size: 16px;
  }

  ul.list {
    width: 65%;
  }

  .linkTitle {
    font-size: 20px;
    margin: 12px 0px;
    padding: 5px;
    font-weight: 400;
  }

  .supportBtn {
    max-height: 170px;
    width: 100%;
  }

  .faqBtn {
    max-height: 170px;
    width: 100%;
  }

  .extraText {
    font-size: 16px;
  }

  .contactBtn {
    max-height: 170px;
    width: 100%;
  }

  .largeTitle {
    font-size: 30px;
  }

  .FAQanswers {
    margin-left: 0px;
  }

  .logoFooter {
    max-height: 40px;
  }

  .scrollBtn {
    height: 24px;
    width: 24px;
  }

  .footerTxt {
    font-size: 16px;
  }
}

.consent-text {
  overflow-y: scroll;
  min-width: 200px;
  height: 500px;
  border: 1px solid #bbafa0;
  padding: 5px 10px;
  font-size: 10pt;
  color: black;
  font-size: 10pt;
  background-color: white;
}
.consent-text p {
  margin-top: 10px;
  font-size: 10pt;
}
.consent-text table {
  margin: 0 auto;
  width: 95%;
  padding: 10px 0px;
  border-collapse: collapse;
  font-size: 10pt;
}
.consent-text table tr,
.consent-text table td {
  border: 1px solid #bbafa0;
  padding: 5px;
  width: 50%;
  font-size: 10pt;
}
.consent-text table.three-cols td {
  width: 33.33%;
  font-size: 10pt;
}
.consent-text table tr.header-row {
  height: 30px;
  font-size: 10pt;
  color: #000000;
  background-color: #ffffff;
}
.consent-text table td ul.left-consent-col {
  margin-left: 15px;
}
.consent-text table td ul {
  list-style: disc;
  margin-left: 15px;
}

.anchor {
  opacity: 0;
  text-decoration: none;
  transition: opacity 0.3s ease-in-out;
}

.navigable:hover > .anchor,
.anchor:hover {
  opacity: 1;
  text-decoration: underline;
}

/* for phones */
@media only screen and (max-width: 480px) {
  .wrapperFAQ img {
    max-width: 100%;
    height: auto;
  }
}

/* for ipads */
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .wrapperFAQ img {
    max-width: 80%;
    height: auto;
  }
}

#FAQRef img {
  max-width: 100%;
}
